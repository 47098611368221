<template>
  <div class="position-relative bg-cover" style="background-image: url('https://i.imgur.com/wAqZsia.png'); background-repeat: no-repeat;">

    <div class="container padding-y-md max-width-lg bg-transparent" :data-theme="`${$store.state.themeslug}default`">
      <div class="grid justify-center">
        <div class="col-12 col-10@md">
          <div class="text-component margin-y-lg">
            <h3 class="text-center color-accent">
              {{welcomeMessage}}, wat wil je leren vandaag?
            </h3>
          </div>
          <form class="margin-bottom-sm position-relative" autocomplete="off">
            <div
              class="flex flex-column flex-row@md justify-between search-box__bg"
            >
              <div class="search-input search-input--icon-left width-100%">
                <input
                  class="form-control width-100% no-border padding-y-md search__input"
                  type="search"
                  name="searchInputX"
                  id="searchInputX"
                  placeholder="Wat wil je weten?"
                  aria-label="Search"
                  v-model="query"
                />
                <button class="search-input__btn" @click.prevent="searchContent()">
                  <svg class="icon" viewBox="0 0 24 24">
                    <title>Submit</title>
                    <g
                      stroke-linecap="square"
                      stroke-linejoin="miter"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-miterlimit="10"
                    >
                      <line x1="22" y1="22" x2="15.656" y2="15.656"></line>
                      <circle cx="10" cy="10" r="8"></circle>
                    </g>
                  </svg>
                </button>
              </div>
              <button
                class="btn btn--primary padding-x-xl"
                @click.prevent="searchContent()"
              >
                Zoeken
              </button>
            </div>
            <ul
              v-if="searchResults && searchResults.length"
              class="search-results__preview padding-y-md padding-x-lg radius-md border"
            >
              <li
                :key="result.id"
                v-for="result in searchResults"
                class="cursor-pointer padding-y-xxs search-results__item radius-md padding-x-xs"
              >
                <router-link
                  :to="{
                    name: 'Content',
                    params: { slug: slugger(result.title), content: result },
                  }"
                  class="no-underline"
                >
                  <svg
                    class="icon color-contrast-low margin-right-md"
                    viewBox="0 0 24 24"
                  >
                    <title>Submit</title>
                    <g
                      stroke-linecap="square"
                      stroke-linejoin="miter"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-miterlimit="10"
                    >
                      <line x1="22" y1="22" x2="15.656" y2="15.656"></line>
                      <circle cx="10" cy="10" r="8"></circle>
                    </g>
                  </svg>
                  {{ result.title }}
                </router-link>
              </li>
            </ul>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { uniqBy } from "lodash";
import slugify from "slugify";
// import Illustration from "@/components/Illustration";

export default {
  name: "Search",
  components: {
  //Illustration,
  },
  data() {
    return {
      search: null,
      query: "",
    };
  },
  created() {
    this.query = '';
    //this.setupSearchIndex();
  },
  methods: {
    slugger(string) {
      return slugify(string);
    },
    searchContent() {
      this.$router.push({ name: "Zoeken", params: { query: this.query } });
      this.query = "";
    },
  },
  computed: {
    searchResults() {
      if (!this.$store.state.searchIndex) return;
      
      let results = ""; 
      const resultsArray = this.$store.state.searchIndex.search(this.query, {expand: true});

      results = this.$store.state.content.filter((contentItem) =>
        resultsArray.some((result) => contentItem.id === result.ref)
      );
      results = uniqBy(results, "title");
      return results.slice(0, 6);
    },
    welcomeMessage() {
      const myDate = new Date();
      const hrs = myDate.getHours();

      let greet;

      if (hrs < 12) {
        greet = "Goedemorgen";
      } else if (hrs >= 12 && hrs <= 17) {
        greet = "Goedemiddag";
      } else if (hrs >= 17 && hrs <= 24) {
        greet = "Goedenavond";
      }

      return greet;
    },
  },
};
</script>
