<template>
  <section :data-theme="`${$store.state.themeslug}soft`" class="padding-y-lg">
    <div class="container max-width-lg">
      <div class="grid grid-gap-sm">
        <div class="col-12">
          <div class="text-component margin-y-sm">
            <h4 class="color-primary">Ons aanbod</h4>
          </div>
        </div>

        <div :key="thema.id" v-for="thema in themas" class="col-6 col-4@md">
          <ThemaCard :thema="thema" />
        </div>
        <div class="col-6 col-4@md">
          <CustomCard />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ThemaCard from "@/components/ThemaCard";
import CustomCard from "@/components/CustomCard";
import { orderBy } from "lodash";

export default {
  name: "Themas",
  components: {
    ThemaCard,
    CustomCard,
  },
  mounted() {
    this.$store.dispatch("GET_CONTENT");
  },
  computed: {
    themas() {
      const url = window.location.href;
      const themas = this.$store.state.themas;
      
      if (url.includes("reinhelpt.nl")) {
        return orderBy(
          themas.filter(thema => thema.positie !== null),
          "positie"
        );
      }
      
      return orderBy(themas, "positie");
    },
  },
};
</script>
